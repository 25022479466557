import { DELIVERY_PRICE, FREE_DELIVERY_MIN_AMOUNT, getBasketTotal } from 'helpers/basketHelpers/basketHelpers'
import { GLN } from 'interfaces/global/gln'
import useTypedSelector from 'interfaces/useTypedSelector'

export const useBasket = () => {
	const { productStatus, products, sidebarOpen, sidebarFixed } = useTypedSelector(({ basket }) => basket)
	const hasKgProduct = products.filter(p => p.product.unit === 'kg').length > 0

	const missingGLNProducts = products.filter(x => !x.product.attributes.gln)
	// Append products that are missing GLN to SS products (This should not happen, but just in case they at least show up)
	const ssProducts = [...products.filter(x => x.product.attributes.gln === GLN.SS), ...missingGLNProducts]
	const rgProducts = products.filter(x => x.product.attributes.gln === GLN.REYKJAGARDUR)
	const hgProducts = products.filter(x => x.product.attributes.gln === GLN.HOLLTOGGOTT)

	const shippingPrice = [ssProducts, rgProducts, hgProducts].reduce((acc, curr) => {
		const amount = curr.length === 0 || getBasketTotal(curr) > FREE_DELIVERY_MIN_AMOUNT ? 0 : DELIVERY_PRICE
		return acc + amount
	}, 0)

	const getBasketProduct = (id: string) => {
		return products.find(x => x.product.id === id) || null
	}

	const getProductQuantity = (id: string) => {
		const product = products.find(p => p.product.id === id)
		return product?.quantity || 0
	}

	const getProductSelectedUnit = (id: string) => {
		const product = products.find(p => p.product.id === id)
		return product?.selectedUnit || 'stk'
	}

	return {
		productStatus,
		products,
		ssProducts,
		rgProducts,
		hgProducts,
		sidebarOpen,
		sidebarFixed,
		hasKgProduct,
		shippingPrice,
		getBasketProduct,
		getProductQuantity,
		getProductSelectedUnit,
	}
}
