import { BasketStorage, BasketStorageProduct, BasketProduct } from '@local-types/products/basketProduct'
import { setInLocalStorage, getInLocalStorage } from 'helpers/storage/storage'
import { LOCAL_STORAGE_BASKET_KEY } from 'constants/storage/storage'
import { getLowestProductPrice, getLowestProductPriceWithoutVAT } from 'helpers/productHelpers/productHelpers'

const getParsedBasket = (): BasketStorage => {
	const basketValue: string | undefined = getInLocalStorage(LOCAL_STORAGE_BASKET_KEY)

	try {
		return basketValue ? JSON.parse(basketValue) : {}
	} catch (err) {
		return {}
	}
}

export const initializeStorageBasket = (companyId: string) => {
	const basket = getParsedBasket()

	basket[companyId] = {
		products: [] as BasketStorageProduct[],
	}

	setInLocalStorage(LOCAL_STORAGE_BASKET_KEY, JSON.stringify(basket))

	return basket[companyId]
}

export const getStorageBasket = (companyId: string) => {
	const basket = getParsedBasket()
	return basket[companyId] || initializeStorageBasket(companyId)
}

export const updateStorageBasket = (companyId: string, products: BasketProduct[]) => {
	const basket = getParsedBasket()

	const updatedBasket: BasketStorage = {
		...basket,
	}

	updatedBasket[companyId] = {
		products: products.map(x => ({
			id: x.product.id,
			quantity: x.quantity,
			selectedUnit: x.selectedUnit,
			isPackage: x.isPackage,
		})),
	}

	setInLocalStorage(LOCAL_STORAGE_BASKET_KEY, JSON.stringify(updatedBasket))
}

export const FREE_DELIVERY_MIN_AMOUNT = 15000
export const DELIVERY_PRICE = 3500

export const getBasketTotal = (products: BasketProduct[]) =>
	products.reduce((prev, curr) => {
		const kgMultiplier =
			curr.product.unit === 'kg' && curr.selectedUnit !== 'kg' ? curr.product.attributes.measurements.weight.value : 1
		const price = curr.product.price !== null ? getLowestProductPrice(curr.product.price) : 0
		return prev + (price || 0) * kgMultiplier * curr.quantity
	}, 0)

export const getBasketTotalWithoutVAT = (products: BasketProduct[]) =>
	products.reduce((prev, curr) => {
		const kgMultiplier =
			curr.product.unit === 'kg' && curr.selectedUnit !== 'kg' ? curr.product.attributes.measurements.weight.value : 1
		const price = curr.product.price !== null ? getLowestProductPriceWithoutVAT(curr.product.price) : 0
		return prev + (price || 0) * kgMultiplier * curr.quantity
	}, 0)

export const getBasketListPriceWithoutVAT = (products: BasketProduct[]) =>
	products.reduce((prev, curr) => {
		const kgMultiplier =
			curr.product.unit === 'kg' && curr.selectedUnit !== 'kg' ? curr.product.attributes.measurements.weight.value : 1
		const price = curr.product.price !== null ? curr.product.price.listPriceWithoutVAT : 0
		return prev + (price || 0) * kgMultiplier * curr.quantity
	}, 0)

export const getMissingDeliveryAmount = (products: BasketProduct[]) => {
	const currentPrice = getBasketTotal(products)
	return FREE_DELIVERY_MIN_AMOUNT > currentPrice ? FREE_DELIVERY_MIN_AMOUNT - currentPrice : 0
}

export const basketContainsCustomProduct = (products: BasketProduct[]) => {
	return products.some(x => x.product.customProduct || !!x.product.customProductMessage)
}
