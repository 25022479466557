import axios, { AxiosResponse } from 'axios'
import { API_URL } from 'constants/paths/paths'
import StorefrontProduct from '@local-types/products/storefrontProduct'
import { fetchWithUserAuth } from 'helpers/authHelpers/authHelpers'
import { Pager } from '@local-types/shared/pager'
import { AllergenCode } from '@local-types/products/codes'

export interface ProductQuery {
	categoryIds?: string[]
	mustIncludeCategoryIds?: string[]
	search?: string
	skus?: string[]
	ids?: string[]
	slugs?: string[]
	allergenCodesFreeFrom?: AllergenCode[]
	page: number
	pageSize?: number
	sort?: string
	aggregations?: any
}

export const get = async (
	params: ProductQuery,
	companyId?: string
): Promise<AxiosResponse<Pager<StorefrontProduct>>> => {
	const config = {
		categoryids: params.categoryIds && params.categoryIds.length > 0 ? params.categoryIds?.join(',') : null,
		// eslint-disable-next-line
		must_include_categories:
			params.mustIncludeCategoryIds && params.mustIncludeCategoryIds.length > 0
				? params.mustIncludeCategoryIds?.join(',')
				: null,
		allergens:
			params.allergenCodesFreeFrom && params.allergenCodesFreeFrom.length > 0
				? params.allergenCodesFreeFrom?.join(',')
				: null,
		search: params.search,
		skus: params.skus?.join(','),
		ids: params.ids?.join(','),
		slugs: params.slugs?.join(','),
		companyId: companyId,
		page: params.page,
		pageSize: params.pageSize ?? 12,
		sort: params.sort,
		aggregations: params.aggregations,
	}

	if (companyId) return fetchWithUserAuth(`${API_URL}/products/`, config)
	return axios(`${API_URL}/products/`, { params: config })
}

export const getByIds = async (ids: string[], companyId?: string): Promise<AxiosResponse<Pager<StorefrontProduct>>> => {
	const config = {
		ids: ids.join(','),
		companyId,
	}

	if (companyId) return fetchWithUserAuth(`${API_URL}/products/`, config)
	return axios(`${API_URL}/products/`, { params: config })
}

export const getPdfProduct = async (id: string): Promise<AxiosResponse<Pager<StorefrontProduct>>> => {
	return axios(`${API_URL}/products/pdf`, { params: { ids: id } })
}

export const getBySlugs = async (
	slugs: string[],
	companyId?: string
): Promise<AxiosResponse<Pager<StorefrontProduct>>> => {
	const config = {
		slugs: slugs.join(','),
		companyId,
	}

	if (companyId) return fetchWithUserAuth(`${API_URL}/products/`, config)
	return axios(`${API_URL}/products/`, { params: config })
}

export const getByCategorySlug = async (
	categoryslug: string,
	companyId?: string,
	pageSize?: number
): Promise<AxiosResponse<Pager<StorefrontProduct>>> => {
	const config = {
		categoryslug,
		companyId,
		pageSize,
	}
	if (companyId) return fetchWithUserAuth(`${API_URL}/products`, config)
	return axios(`${API_URL}/products`, { params: config })
}
