import { BasketStorageProduct, BasketProduct } from '@local-types/products/basketProduct'
import { OrderBasket } from '@local-types/orders/order'
import { createReducer, createAction } from '@reduxjs/toolkit'
import products from 'apiService/products'
import { AppThunk } from 'store'
import { AsyncActionState } from 'interfaces/global/asyncActionState'
import StorefrontProduct, { ProductUnit } from '@local-types/products/storefrontProduct'
import { updateStorageBasket } from 'helpers/basketHelpers/basketHelpers'
import { getProductBaseQuantity, isSoldInPackages } from 'helpers/productHelpers/productHelpers'

export interface FetchBasketFulfilled {
	products: Array<BasketProduct>
	companyId: string
}

export const fetchBasketPending = createAction<string>('fetch-basket-pending')
export const fetchBasketFulfilled = createAction<FetchBasketFulfilled>('fetch-basket-fulfilled')
export const fetchBasketFailed = createAction<string>('fetch-basket-failed')
export const fetchBasket = (
	companyId: string,
	basketStorageProducts: BasketStorageProduct[]
): AppThunk => async dispatch => {
	dispatch(fetchBasketPending(companyId))

	if (basketStorageProducts.length === 0) {
		dispatch(
			fetchBasketFulfilled({
				companyId,
				products: [],
			})
		)
		return
	}

	try {
		const ids = basketStorageProducts.map(x => x.id)
		const res = await products.getByIds(ids, companyId)
		const basketItems: BasketProduct[] = res.data.results.reduce((prev, curr) => {
			const storageProd = basketStorageProducts.find(x => x.id === curr.id)
			if (storageProd)
				prev.push({
					quantity: storageProd.quantity,
					product: curr,
					selectedUnit: storageProd.selectedUnit,
					isPackage: storageProd.isPackage,
				})
			return prev
		}, [] as BasketProduct[])
		dispatch(
			fetchBasketFulfilled({
				companyId,
				products: basketItems,
			})
		)
	} catch (err) {
		dispatch(fetchBasketFailed(companyId))
	}
}

export const updateBasketItem = createAction<{
	product: StorefrontProduct
	quantity: number
	selectedUnit: ProductUnit
	isPackage: boolean
}>('update-basket-item')
export const batchUpdateBasketItems = createAction<{ basket: OrderBasket }>('batch-update-basket-items')
export const batchAddListItems = createAction<Array<StorefrontProduct>>('batch-add-list-items')
export const clearBasketItems = createAction('clear-basket-items')
export const setBasketVisibleState = createAction<boolean>('set-basket-visible-state')
export const setBasketFixedState = createAction<boolean>('set-basket-fixed-state')

export interface BasketReducer {
	productStatus: AsyncActionState
	activeCompanyId: string | null
	products: Array<BasketProduct>
	sidebarOpen: boolean
	sidebarFixed: boolean
}

const initialState: BasketReducer = {
	productStatus: 'passive',
	activeCompanyId: null,
	products: [],
	sidebarOpen: false,
	sidebarFixed: false,
}

const basketReducer = createReducer(initialState, builder =>
	builder
		.addCase(fetchBasketPending, store => {
			store.productStatus = 'pending'
		})
		.addCase(fetchBasketFulfilled, (store, action) => {
			store.productStatus = 'fulfilled'
			store.activeCompanyId = action.payload.companyId
			store.products = action.payload.products
		})
		.addCase(fetchBasketFailed, store => {
			store.productStatus = 'failed'
			store.products = []
		})
		.addCase(updateBasketItem, (store, action) => {
			const { product, quantity, selectedUnit, isPackage } = action.payload
			const basketProduct = store.products.find(x => x.product.id === product.id)

			if (quantity === 0) store.products = store.products.filter(x => x.product.id !== product.id)
			else {
				if (basketProduct) {
					basketProduct.quantity = quantity
					basketProduct.selectedUnit = selectedUnit
					basketProduct.isPackage = isPackage
				} else
					store.products.push({
						product,
						quantity,
						selectedUnit,
						isPackage: isPackage || false,
					})
			}

			updateStorageBasket(store.activeCompanyId as string, store.products)
		})
		.addCase(batchUpdateBasketItems, (store, action) => {
			const { basket } = action.payload
			for (const orderLine of basket.orderLines) {
				const product = orderLine.product
				const basketProduct = store.products.find(x => x.product.id === product.id)
				if (basketProduct) {
					basketProduct.quantity = orderLine.quantity
					basketProduct.selectedUnit = orderLine.unit
					basketProduct.isPackage = orderLine.isPackage
				} else
					store.products.push({
						product,
						quantity: orderLine.quantity,
						selectedUnit: orderLine.unit,
						isPackage: orderLine.isPackage || false,
					})
			}
			updateStorageBasket(store.activeCompanyId as string, store.products)
		})
		.addCase(batchAddListItems, (store, action) => {
			const products = action.payload
			for (const product of products) {
				const basketProduct = store.products.find(x => x.product.id === product.id)
				if (basketProduct) {
					continue
				}
				const isPackage = isSoldInPackages(product.attributes)
				store.products.push({
					product,
					quantity: getProductBaseQuantity(product, isPackage),
					selectedUnit: product.unit,
					isPackage: isPackage,
				})
			}
			updateStorageBasket(store.activeCompanyId as string, store.products)
		})
		.addCase(clearBasketItems, store => {
			store.products = []
			updateStorageBasket(store.activeCompanyId as string, [])
		})
		.addCase(setBasketVisibleState, (store, action) => {
			store.sidebarOpen = action.payload
		})
		.addCase(setBasketFixedState, (store, action) => {
			store.sidebarFixed = action.payload
		})
)

export default basketReducer
