import { createAction, createReducer } from '@reduxjs/toolkit'

export type ModalTypes =
	| ''
	| 'forgotten-password'
	| 'company-picker'
	| 'company-user-modal'
	| 'invite-user'
	| 'not-logged-in'
	| 'add-to-product-list'
	| 'create-product-list'
	| 'delete-product-list'
	| 'edit-product-list'
	| 'no-active-relation'
	| 'remove-product-from-list'
	| 'custom'

export const setActiveModal = createAction<ModalTypes>('set-active-modal')

export interface HeaderReducer {
	activeModal: ModalTypes
}

const initialState: HeaderReducer = {
	activeModal: '',
}

const modalReducer = createReducer(initialState, builder =>
	builder.addCase(setActiveModal, (store, action) => {
		store.activeModal = action.payload
	})
)

export default modalReducer
