export const formatCurrency = (x: number | string) => {
	let price = x.toString()

	// Remove all trailing numbers
	// fx 345.0000432
	if (price.indexOf('.') >= 0) price = price.substring(0, price.indexOf('.'))

	// Insert icelandic number dots at the correct places
	price = price.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

	return price
}

const monthsIS = [
	'janúar',
	'febrúar',
	'mars',
	'apríl',
	'maí',
	'júní',
	'júlí',
	'ágúst',
	'september',
	'október',
	'nóvember',
	'desember',
]

export const formatDateIS = (date: Date) => {
	return `${date.getDate()}. ${monthsIS[date.getMonth()]} ${date.getFullYear()}`
}

export const numberAsCommaSeparatedString = (value: number): string => value.toString().replace('.', ',')
