import { AuthState } from 'reducers/authReducer'
import { StorefrontCompanyUser } from '@local-types/user/storefrontUser'
import StorefrontProduct, {
	ProductPriceInfo,
	ProductUnit,
	ProductAttributes,
} from '@local-types/products/storefrontProduct'
import { AsyncActionState } from 'interfaces/global/asyncActionState'
import { formatCurrency } from 'helpers/stringHelpers/stringHelpers'
import { RecentProduct, RecentProductsStorage, StorageRecentProduct } from '@local-types/products/recentProduct'
import { getInLocalStorage, setInLocalStorage } from 'helpers/storage/storage'
import { LOCAL_STORAGE_RECENT_PRODUCTS_KEY, MAX_RECENT_PRODUCTS } from 'constants/storage/storage'
import { LabelValue } from 'components/products/productLabel/productLabel'
import { getByIds } from 'apiService/products/products'

export const getCroppedProdImg = (img: string, width: number, height: number): string =>
	img.replace('{width}', width.toString()).replace('{height}', height.toString())

// Unauthenticated fetch:
// The user is either:
// - Not logged in
// - Logged in but does not have access to any company reg numbers
export const shouldPerformUnAuthenticatedProductFetch = (
	authState: AuthState,
	userInfoState: AsyncActionState,
	activeRelation: StorefrontCompanyUser | undefined
) => authState === 'notLoggedIn' || (userInfoState === 'fulfilled' && !activeRelation)

// Authenticated fetch:
// - The user is logged in
// - The user has access to registration numbers
// - The active registration number is not the same as before or the page has changed
export const shouldPerformAuthenticatedProductFetch = (
	userInfoState: AsyncActionState,
	companyId: string | undefined,
	activeRelation: StorefrontCompanyUser | undefined,
	activePage: number,
	page: number
) => userInfoState === 'fulfilled' && (companyId !== activeRelation?.company.id || activePage !== page)

export const shouldUseSpecialPrice = (price: ProductPriceInfo) =>
	price.specPriceWithoutVAT !== null &&
	price.specPriceWithoutVAT > 0 &&
	price.specPriceWithoutVAT < price.custPriceWithoutVAT &&
	price.specPriceWithoutVAT < price.listPriceWithoutVAT

export const shouldUseCustomerPrice = (price: ProductPriceInfo) =>
	price.custPriceWithoutVAT !== 0 &&
	price.custPriceWithoutVAT !== null &&
	price.custPriceWithoutVAT < price.listPriceWithoutVAT

const renderPrice = (price: number, unit: ProductUnit) => `${formatCurrency(price || '')} ${unit && `kr / ${unit}.`}`

export const renderProductsSpecPrice = (price: ProductPriceInfo, unit: ProductUnit) => {
	return renderPrice(price.specPriceWithoutVAT, unit)
}

export const renderProductCustPrice = (price: ProductPriceInfo, unit: ProductUnit) => {
	return renderPrice(price.custPriceWithoutVAT, unit)
}

export const renderProductListPrice = (price: ProductPriceInfo, unit: ProductUnit) => {
	return renderPrice(price.listPriceWithoutVAT, unit)
}

export const getFormattedLowestProductPrice = (price: ProductPriceInfo, unit: ProductUnit) =>
	shouldUseCustomerPrice(price) ? renderProductCustPrice(price, unit) : renderProductListPrice(price, unit)

export const getLowestProductPrice = (price: ProductPriceInfo) =>
	shouldUseCustomerPrice(price) ? price.custPriceWithVAT : price.listPriceWithVAT

export const getLowestProductPriceWithoutVAT = (price: ProductPriceInfo) =>
	shouldUseCustomerPrice(price) ? price.custPriceWithoutVAT : price.listPriceWithoutVAT

export const getProductBaseQuantity = (product: StorefrontProduct, isPackage: boolean) => {
	const maxUnitsPP = product.attributes.quantityInfo.maxUnitsPerPackage
	const unitsPP = product.attributes.quantityInfo.unitsPerPackage
	return isPackage ? (maxUnitsPP > unitsPP ? maxUnitsPP : unitsPP) : 1
}

export const isSoldInPackages = (attributes: ProductAttributes) => {
	const { maxUnitsPerPackage, unitsPerPackage } = attributes.quantityInfo
	return maxUnitsPerPackage > 1 || unitsPerPackage > 1
}

export const isPackageSoldInUnits = (attributes: ProductAttributes) =>
	attributes.quantityInfo.maxUnitsPerPackage > attributes.quantityInfo.unitsPerPackage

export const getMinimumUnitInPackage = (attributes: ProductAttributes) => {
	return isPackageSoldInUnits(attributes)
		? attributes.quantityInfo.maxUnitsPerPackage
		: attributes.quantityInfo.unitsPerPackage
}

// Workaround for JavaScript floating point calculation error
// before: 0.1 + 0.2 = 0.30000000000000004
// after: getFixedFloatingPoint(0.1 + 0.2) = 0.3
export const getFixedFloatingPoint = (quantity: number) => {
	return +Math.max(quantity, 0).toFixed(2)
}

export const getParsedRecentProducts = (): RecentProductsStorage => {
	const value: string | undefined = getInLocalStorage(LOCAL_STORAGE_RECENT_PRODUCTS_KEY)

	try {
		return value ? JSON.parse(value) : {}
	} catch (err) {
		return {}
	}
}

export const initializeRecentProductStorage = (userId: string) => {
	const recentProducts = getParsedRecentProducts()

	recentProducts[userId] = {
		products: [] as StorageRecentProduct[],
	}

	setInLocalStorage(LOCAL_STORAGE_RECENT_PRODUCTS_KEY, JSON.stringify(recentProducts))
	return recentProducts[userId]
}

export const getStorageRecentProducts = (userId: string) => {
	const recentProducts = getParsedRecentProducts()
	return recentProducts[userId] || initializeRecentProductStorage(userId)
}

/**
 * @param productsToUpdate the products for session to be set to local storage
 * @param existingRecentProducts prior products, possibly from an earlier session
 * @returns new content of unique products, including products from earlier sessions */
const setNewStorageProducts = (
	productsToUpdate: StorageRecentProduct[],
	existingRecentProducts: StorageRecentProduct[]
) => {
	const existingRecentProductIds = existingRecentProducts.map(p => p.id)
	const newStorageProducts = existingRecentProducts
	for (let i = 0; i < productsToUpdate.length; i++) {
		const product = productsToUpdate[i]
		if (!existingRecentProductIds.includes(product.id)) {
			newStorageProducts.push(productsToUpdate[i])
		}
	}

	return newStorageProducts
}

export const updateRecentProductStorage = (userId: string, products: RecentProduct[]): RecentProduct[] => {
	const recentProducts = getParsedRecentProducts()
	const updated: RecentProductsStorage = {
		...recentProducts,
	}
	const productsToUpdate = products.map(x => ({
		id: x.product.id,
		date: x.date,
	}))
	let newStorageProducts = recentProducts[userId]
		? setNewStorageProducts(productsToUpdate, recentProducts[userId].products)
		: productsToUpdate
	if (newStorageProducts.length >= MAX_RECENT_PRODUCTS + 1) {
		newStorageProducts = newStorageProducts.slice(1).slice(-MAX_RECENT_PRODUCTS)
	}
	updated[userId] = {
		products: newStorageProducts,
	}
	setInLocalStorage(LOCAL_STORAGE_RECENT_PRODUCTS_KEY, JSON.stringify(updated))
	const recentItems: RecentProduct[] = products.reduce((prev, curr) => {
		const storageProd = newStorageProducts.find(x => x.id === curr.product.id)
		if (storageProd)
			prev.push({
				date: storageProd.date,
				product: curr.product,
			})
		return prev
	}, [] as RecentProduct[])
	return recentItems
}

const getAvailabilityFromPrice = (stock: string | undefined): boolean => {
	if (!stock) return false
	return +stock === 0 ? true : false
}
const milliSecondsToDays = (ms: number) => ms / (1000 * 60 * 60 * 24)

const shouldDisplayNewLabel = (startDate: string | null) => {
	if (startDate === null) return false
	const date = new Date(startDate)
	const month = 28
	const start = new Date().setHours(0, 0, 0, 0),
		end = date.setHours(0, 0, 0, 0)
	const daysBetween = milliSecondsToDays(Math.abs(end - start))
	return daysBetween < month
}

export const getLabels = (product: StorefrontProduct | null): LabelValue[] => {
	if (!product) return []
	const { price, startAvailabilityDate } = product
	const isProductOutOfStock = getAvailabilityFromPrice(price?.status)
	const displayNewLabel = shouldDisplayNewLabel(startAvailabilityDate)

	const labels: LabelValue[] = []

	if (isProductOutOfStock) {
		labels.push('Væntanlegt')
	}
	if (displayNewLabel) {
		labels.push('Nýtt')
	}
	if (product.frozen) {
		labels.push('Frostvara')
	}

	return labels
}

export const hasSpecialPrice = (price: ProductPriceInfo | null): boolean => {
	if (!price || !price.specPriceWithVAT) return false
	return price.specPriceWithVAT > 0
}

// Validates products, i.e. filter out ids that are no longer available,
// that is have higher start date from today
export const validateProducts = async (ids: string[]) => {
	try {
		const res = await getByIds(ids)
		const today = new Date()
		return res.data.results
			.filter(r => r && new Date(r.startAvailabilityDate).getTime() <= today.getTime())
			.map(r => r.id)
	} catch (err) {
		console.log('err', err)
	}
}
